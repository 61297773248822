import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import loadable from '@loadable/component';

import { VIEW_OPTIONS_TILES } from '../SearchResultsPage/SRP.definitions';

//todo: for some reason while aditing exactly this file it doesn't get updated with hot reload
import { ReactComponent as WishListIcon } from '../../images/icons/wishlist_transparent.svg';
import { ReactComponent as WishListSelectedIcon } from '../../images/icons/wishlist.svg';
import { ReactComponent as GoToDetailsIcon } from '../../images/icons/expand.svg';

import styles from './Product.module.scss';
import { setAnonymousRecentlyViewedProducts } from '../../state/anonymousRecentlyViewedProducts/anonymousRecentlyViewedProducts';
import { Link } from 'react-router-dom';
import { SwitchableFeature, useSwitchableFeatures } from 'Features/SwitchableFeature/SwitchableFeature';
import Price from '../Price/Price';
import { CART, PRODUCT_CARD_TO_PDP_LINK } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import { useUserProfile } from '../../utils/useUserProfile';
import { parseValidJSON } from '../../utils/parseValidJSON';
import useWishlist from '../../utils/useWishlist';
import ProductImage from '../ProductImage/ProductImage';
import ToggleAdultContent from '../ToggleAdultContent';
import useAdultContentHandlers from '../../utils/useAdultContentHandlers';

const RatingShort = loadable(() => import('../RatingShort/RatingShort'));

const ProductWrapperArticle = (props) => <article {...props} />;
const ProductWrapperLink = (props) => <Link {...props} />;

const ProductWrapper = (props) => {
  const children = props.children;
  const htmlAttributes = { ...props };
  delete htmlAttributes.children;
  delete htmlAttributes.to;

  return (
    <SwitchableFeature
      feature={PRODUCT_CARD_TO_PDP_LINK}
      //todo: check if it really makes sense to pass kinda such function instead of function component directly
      fallbackFn={() => <ProductWrapperArticle {...htmlAttributes}>{children}</ProductWrapperArticle>}
    >
      <ProductWrapperLink to={props.to} {...htmlAttributes}>
        {children}
      </ProductWrapperLink>
    </SwitchableFeature>
  );
};

const Product = React.memo(
  ({
    classList = {},
    shortDesc = '',
    id,
    url,
    adult,
    image,
    isSelected = false,
    name,
    onClick,
    price,
    profile,
    originalPrice,
    status,
    setRecentlyViewProductsAnonymous,
    shop,
    shopRating,
    showProductLink = true,
    view = VIEW_OPTIONS_TILES,
    isFashionView = false,
    isInViewPort,
    hideDescription = false,
    isShortName = false,
    showShopInfo = false,
  }) => {
    const intl = useIntl();
    const [marketplaceVersion] = useSwitchableFeatures([CART]);
    const { addViewedProduct } = useUserProfile();
    const { isWishlistItem, toggleWishlistItem } = useWishlist();
    const { adultContentAllowed } = useAdultContentHandlers();

    const isLikedProduct = isWishlistItem(id);

    const saveRecentlyViewedProductAnonymously = useCallback(
      (productId) => {
        try {
          const dt = new Date();
          const viewingDate = new Date(Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate(), 0, 0, 0));
          const recentlyViewedProducts = parseValidJSON(localStorage.getItem('rVP')) || [];
          const hasProductInList = recentlyViewedProducts.some((item) => item.productId === productId);

          if (!hasProductInList) {
            recentlyViewedProducts.push({ date: viewingDate.toISOString().substr(0, 10), productId });
          }

          const limitedRvp = recentlyViewedProducts.slice(-10);
          localStorage.setItem('rVP', JSON.stringify(limitedRvp));
          setRecentlyViewProductsAnonymous(limitedRvp);
        } catch (e) {
          console.error("Can't save RVP locally", e);
        }
      },
      [setRecentlyViewProductsAnonymous]
    );

    const saveRecentlyViewedProductToProfile = useCallback(
      (productId) => {
        addViewedProduct({
          body: JSON.stringify({ id: productId }),
        }).catch((reason) => {
          console.error("Can't save local rvp to profile:", reason);
        });
      },
      [addViewedProduct]
    );

    const discount = ((originalPrice - price) / originalPrice) * 100;

    const productClickHandler = useCallback(
      async (event) => {
        if (event?.target?.href) return;
        if (onClick) onClick(event);
        if (!profile) {
          saveRecentlyViewedProductAnonymously(id);
        } else {
          saveRecentlyViewedProductToProfile(id, profile);
        }
      },
      [id, onClick, profile, saveRecentlyViewedProductAnonymously, saveRecentlyViewedProductToProfile]
    );

    const stopPropagation = (e) => e.stopPropagation();

    const handleWishlistBtnPress = (event) => {
      event.stopPropagation();
      event.preventDefault();
      toggleWishlistItem(id);
    };
    const isColumnView = view !== VIEW_OPTIONS_TILES;
    const imgLoadingAttr = isInViewPort ? 'eager' : 'lazy';

    return (
      <>
        <ProductWrapper
          to={url}
          className={classnames(styles.root, classList.root, classList.layout, {
            [styles.selected]: isSelected,
            [styles.productClothing]: isFashionView && view === VIEW_OPTIONS_TILES,
            [styles.marketplaceLayout]: marketplaceVersion,
            [styles.oldLayout]: !marketplaceVersion,
            [styles.columnLayout]: isColumnView,
          })}
          id={id}
          onClick={productClickHandler}
          data-testid="Product"
        >
          {status && discount < 5 && (
            <span className={classnames(styles.new, styles.badge, { [styles.badgeColumnView]: isColumnView })}>
              <FormattedMessage id="common.new" defaultMessage="NEW" />
            </span>
          )}
          {discount >= 5 && !status && (
            <span
              className={classnames(styles.discount, styles.badge, { [styles.badgeColumnView]: isColumnView })}
            >{`-${Number(discount).toFixed(0)}%`}</span>
          )}
          {discount >= 5 && status && (
            <span
              className={classnames(styles.badge, styles.discountAndNew, { [styles.badgeColumnView]: isColumnView })}
            >
              <span className={styles.discount}>{`-${Number(discount).toFixed(0)}%`}</span>
              <span className={styles.new}>
                <FormattedMessage id="common.new" defaultMessage="NEW" />
              </span>
            </span>
          )}
          <ProductImage
            adult={adult}
            classList={{ root: styles.productImage, quickView: styles.quickView, adultPic: styles.adultContentPic }}
            forbiddenAdultContent={adult && !adultContentAllowed}
            imgLoadingAttr={imgLoadingAttr}
            image={image}
            title={name}
            showProductLink={showProductLink}
            url={url}
            isFashionView={isFashionView}
          />
          {adult && <ToggleAdultContent classList={{ root: styles.toggleAdultContent }} />}
          <div
            className={classnames(styles.productName, {
              [styles.shortProductName]: isShortName,
            })}
            data-testid="ProductName"
          >
            {name}
          </div>
          {!hideDescription && <div className={styles.productDescription}>{shortDesc}</div>}
          {showShopInfo && !marketplaceVersion && (
            <div className={styles.productShop}>
              <span className={styles.productShopName}>{shop.name}</span>
              <RatingShort title={intl.formatMessage({ id: 'product.shopRatingTitle' })} value={shopRating} />
            </div>
          )}
          <div className={styles.productPrice}>
            {price && (
              <Price
                showDiscount={discount > 5}
                classList={{ root: styles.priceComponent }}
                price={price}
                previousPrice={originalPrice}
                currency={process.env.REACT_APP_WEBSITE_CURRENCY}
                isCurrencyShown={false}
              />
            )}
          </div>
          {(!adult || !!adultContentAllowed) && isLikedProduct && (
            <FormattedMessage id="profile.wishlistRemove" defaultMessage="Remove from wishlist">
              {(label) => (
                <button
                  aria-label={label}
                  className={classnames(styles.wishlistBtn, { [styles.wishlistSelectedBtn]: isLikedProduct })}
                  onClick={handleWishlistBtnPress}
                >
                  <WishListSelectedIcon />
                </button>
              )}
            </FormattedMessage>
          )}
          {(!adult || !!adultContentAllowed) && !isLikedProduct && (
            <FormattedMessage id="profile.wishlistAdd" defaultMessage="Add to wishlist">
              {(label) => (
                <button
                  aria-label={label}
                  className={classnames(styles.wishlistBtn, { [styles.wishlistSelectedBtn]: isLikedProduct })}
                  onClick={handleWishlistBtnPress}
                >
                  <WishListIcon />
                </button>
              )}
            </FormattedMessage>
          )}
          {showProductLink && (
            <FormattedMessage id="product.moreInfo" defaultMessage="More info">
              {(label) => (
                <Link
                  aria-label={label}
                  className={styles.goToPdp}
                  data-testid="ProductGoToPDP"
                  onClick={stopPropagation}
                  to={url}
                  noopener="true"
                  norefferer="true"
                >
                  <GoToDetailsIcon width="0.875rem" height="0.875rem" />
                </Link>
              )}
            </FormattedMessage>
          )}
        </ProductWrapper>
      </>
    );
  }
);

Product.displayName = 'Product';
Product.propTypes = {
  id: PropTypes.string,
  showProductLink: PropTypes.bool,
  classList: PropTypes.shape({
    root: PropTypes.string,
    layout: PropTypes.string,
  }),
  isInViewPort: PropTypes.bool,
};

const mapStateToProps = ({ config, profile }) => ({ config, profile });
const mapDispatchToProps = (dispatch) => ({
  setRecentlyViewProductsAnonymous: (recentlyViewedProducts) => {
    dispatch(setAnonymousRecentlyViewedProducts(recentlyViewedProducts));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(Product);
